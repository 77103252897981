<template>
    <div style="padding-top: 8em;">
        <header-service :title="'PENDAFTARAN MEREK'" :subtitle="'Legalpedia siap melayani Jasa Pendaftaran Merek sesuai kebutuhan Anda. Merek adalah tanda yang dapat ditampilkan secara grafis berupa gambar, logo, nama, kata, huruf, angka, susunan warna, dalam bentuk 2 (dua) dimensi dan/atau 3 (tiga) dimensi, suara, hologram, atau kombinasi dari 2 (dua) atau lebih. '" :text_css="'text-left'"></header-service>
        <section>
            <v-container class="mt-2">
                <v-row>
                    <v-col cols="12" class="mb-0 mt-0">
                        <v-breadcrumbs :items="breadcumbs" class="mb-1 pb-1 breadcrumb">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :to="item.href"
                                    class="text-subtitle-2 crumb-item "
                                    :disabled="item.disabled"
                                    exact
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="mt-8 mb-8" id="start">
            <v-container class="container">
                <v-row>
                    <div class="col-md-3 mb-5">
                        <div class="card sub-service-card">
                            <div class="card-body" style="height: 500px;">
                                <img src="@/assets/cek-merk.png" class="center mb-3 mt-3 img-fluid">
                                <a href="#" data-toggle="modal" data-target="#proposal" class="text-center">
                                    <h5>Pengecekan <br> Merek</h5>
                                </a>
                                <p class="font-size-14 mb-5 text-center">
                                    Pengecekan merek untuk mengetahui peluang keberhasilan pendaftaran merek
                                </p>
                                <div class="custom-currency text-danger p-0 bold">
                                    FREE*
                                </div>
                            </div>
                            <div class="card-footer border-0 text-center">
                                <v-btn color="success">Pesan Sekarang</v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 mb-5" v-for="(detail, index) in services" :key="index">
                        <div class="card sub-service-card">
                            <div class="card-body" style="height: 500px;">
                                <img :src="detail.image" class="center mb-3 mt-3 img-fluid" style="min-height: 160px;min-width: 160px;" v-if="detail.image">
                                <img src="@/assets/merk-3.png" class="center mb-3 mt-3 img-fluid" style="min-height: 160px;min-width: 160px;" v-else>
                                <a href="#" data-toggle="modal" data-target="#proposal" class="text-center">
                                    <h5>{{detail.title}}</h5>
                                </a>
                                <p class="font-size-14 text-center">
                                    {{detail.description}}
                                </p>
                                <div style="height: 110px;">
                                    <ol class="list-group list-group-numbered">
                                        <li class="font-size-12" v-for="(syarat, index) in detail.service_item_syarats" :key="index">{{syarat.name}}</li>
                                    </ol>
                                </div>
                                
                            </div>
                            <div class="card-footer border-0 text-center">
                                <p class="mb-0 pb-0 mt-4">Mulai dari</p>
                                <div class=" text-danger p-0 bold mb-4 text-center">
                                    IDR.  {{detail.price }}
                                </div>
                                <a :href="detail ? detail.url_link_wa : 'https://api.whatsapp.com/send?phone=+6282120938886&text=Hi%20LegalPedia,%20Saya%20ingin%20konsultasi%20.%20Mohon%20Dibantu'" target="_blank">
                                    <v-btn color="success">Pesan Sekarang</v-btn>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                </v-row>
            </v-container>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'

import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"
import {service_item_api} from "@/backend-api/service_item_api"

export default {
    components: {
        ContactUs, HeaderService
    },
    data: () => ({
        data : '',
        panel:[0,1,2,3],
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Pendaftaran Merek',
                disabled: true,
            }
        ],
        services: []
    }),
    methods:{
        async fetchData(){
            var respData = await service_item_api.front(`?service_id=8`, null, false, false, false)
            if (respData.status === 200) {
                this.services = respData.data.data
            } 
        },
    },
    async mounted(){
        await this.fetchData()
    },
    watch: {}
}
</script>

<style scoped>

</style>